<script lang="ts">
const CODE_LENGTH = 4;
</script>

<script setup lang="ts">
import { ref } from 'vue';

import { WsButton } from '@mfl/common-components';

import WisestampLogo from './wisestamp-logo.vue';
import EmailForm from './email-form.vue';
import CodeForm from './code-form.vue';
import strings from './employee-portal-signin.strings';

const email = ref<string>();
const expirationTimestamp = ref<number>();
</script>

<template>
  <div class="relative w-[800px] rounded-[20px] bg-white p-[100px]">
    <span class="sr-only">WiseStamp Employee Portal Sign In</span>
    <WisestampLogo aria-hidden="true" class="mb-10 w-[161px]" />

    <EmailForm
      v-if="!email || !expirationTimestamp"
      @code-sent="
        email = $event.email;
        expirationTimestamp = $event.expirationTimestamp;
      "
    />

    <template v-else>
      <WsButton
        aid="BACK_BUTTON"
        variant="flat"
        size="sm"
        color="gray-500"
        icon="fa-regular fa-chevron-left"
        class="absolute start-4 top-4 !pe-4 !ps-3"
        @click="email = expirationTimestamp = undefined"
      >
        {{ strings.email_step.back_button }}
      </WsButton>

      <CodeForm
        :email="email"
        :code-length="CODE_LENGTH"
        :expiration-timestamp="expirationTimestamp"
        @code-resent="expirationTimestamp = $event.expirationTimestamp"
      />
    </template>
  </div>
</template>

export default {
  email_step: {
    heading: 'Sign in to employee hub',
    paragraph: 'We suggest using the email address you use at work.',
    required: 'Email address is required',
    invalid: 'Invalid email address',
    employee_not_found: 'Employee not found',
    submit_button: 'Sign in',
    back_button: 'Back',
  },
  code_step: {
    heading: 'Check your email for the code',
    paragraph:
      "We've sent a {digits}–digit code to <strong>{email}</strong>. The code expires shortly, so please enter it soon.",
    invalid: 'Code is invalid, please try again',
    too_many_attempts: 'Too many attempts, please try again later',
    expired: 'Your code has expired. Click “resend” to receive a new code',
    unexpected_error:
      'An unexpected error occurred. If the issue persists, please report it to support@wisestamp.com',
    code_resend_timer: 'New code available in {minutes} minutes',
    resend_prompt: "Didn't receive the code?",
    resend_button: 'Click to resend',
    code_resend_success: 'A new code has been sent to your email.',
  },
};

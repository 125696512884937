import { useQuasarPlugin } from '@mfl/common-components';
import { bootstrapMicrofrontend } from '@mfl/vue-common';
import { employeeAuthGateway } from '@wisestamp/employee-auth-gateway-sdk';

import mfe from './employee-portal-signin-mfe.vue';
import './employee-portal-signin.css';

void employeeAuthGateway.init();

export const { bootstrap, mount, unmount } = bootstrapMicrofrontend({
  name: 'employee-portal-signin',
  mainComponent: mfe,
  includeCss: true, //['/employee-portal-signin/employee-portal-signin.css'],
  handleVueApp: (app) => {
    useQuasarPlugin(app);
  },
});

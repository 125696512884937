<script setup lang="ts">
import { toTypedSchema } from '@vee-validate/zod';
import { useForm } from 'vee-validate';
import { computed, onBeforeUnmount, ref, watchEffect } from 'vue';
import { OTPInput } from 'vue-input-otp';
import { z } from 'zod';

import { WsAlert } from '@mfl/common-components';
import { employeeAuthGateway } from '@wisestamp/employee-auth-gateway-sdk';

import strings from './employee-portal-signin.strings';

const props = defineProps<{
  email: string;
  codeLength: number;
  expirationTimestamp: number;
}>();

const emit = defineEmits<{
  codeResent: [payload: { expirationTimestamp: number }];
}>();

const form = ref<HTMLFormElement | null>(null);
const otpInput = ref<typeof OTPInput | null>(null);

function focusOtpInput() {
  const input = otpInput.value?.ref as HTMLInputElement | undefined;
  input?.focus();
}

const validationSchema = toTypedSchema(
  z.object({
    code: z
      .string()
      .length(props.codeLength, { message: strings.code_step.invalid })
      .regex(/^\d+$/, { message: strings.code_step.invalid }),
  })
);

const { defineField, handleSubmit, errors, isSubmitting, setFieldError } =
  useForm({ validationSchema });

const [code, codeProps] = defineField('code', {
  validateOnBlur: false,
  validateOnModelUpdate: false,
});

function getCookieDomain() {
  const hostname = window.location.hostname;
  if (hostname.includes('localhost')) {
    return 'localhost';
  } else if (hostname.includes('wisestamp-dev.com')) {
    return '.wisestamp-dev.com';
  } else {
    return '.wisestamp.com';
  }
}

const signIn = handleSubmit(async (values, actions) => {
  try {
    const res = await employeeAuthGateway.generateTokens({
      email: props.email,
      passcode: values.code,
    });

    if (!res.ok) {
      switch (res.reason) {
        case 'INVALID_PASSCODE':
          actions.setFieldError('code', strings.code_step.invalid);
          break;
        case 'TOO_MANY_INVALID_ATTEMPTS':
          actions.setFieldError('code', strings.code_step.too_many_attempts);
          break;
        case 'EXPIRED_PASSCODE':
          actions.setFieldError('code', strings.code_step.expired);
          break;
        default:
          actions.setFieldError('code', strings.code_step.unexpected_error);
      }

      actions.setFieldValue('code', '', false);

      setTimeout(() => {
        focusOtpInput();
      });

      return;
    }
    const expires = new Date(
      Date.now() + 60 * 24 * 60 * 60 * 1000
    ).toUTCString(); // 60 days
    document.cookie = `ws_employee_refresh_token=${res.result?.refreshToken}; expires=${expires}; domain=${getCookieDomain()}; path=/`;
    location.pathname = '/signatures';
  } catch (err) {
    console.error(err);
    actions.setFieldError('code', strings.code_step.unexpected_error);
  }
});

const millisecondsLeft = ref(0);

let interval: ReturnType<typeof setInterval>;

watchEffect((onCleanup) => {
  millisecondsLeft.value = props.expirationTimestamp - Date.now();

  interval = setInterval(() => {
    millisecondsLeft.value = props.expirationTimestamp - Date.now();

    if (millisecondsLeft.value <= 0) {
      clearInterval(interval);
    }
  }, 1000);

  onCleanup(() => clearInterval(interval));
});

onBeforeUnmount(() => {
  clearInterval(interval);
});

const secondsLeft = computed(() =>
  Math.max(0, Math.floor(millisecondsLeft.value / 1000))
);

function getTimeString(seconds: number) {
  const minutesString = Math.floor(seconds / 60)
    .toString()
    .padStart(2, '0');
  const secondsString = (seconds % 60).toString().padStart(2, '0');
  return `${minutesString}:${secondsString}`;
}

const resending = ref(false);
const resendSuccess = ref(false);

async function resendCode() {
  resending.value = true;

  try {
    const res = await employeeAuthGateway.generatePassCode({
      email: props.email,
    });

    if (
      !res.expirationTimestamp ||
      (!res.ok && res.reason !== 'PASSCODE_ALREADY_SENT')
    ) {
      throw new Error('Invalid response from the server');
    }

    emit('codeResent', { expirationTimestamp: res.expirationTimestamp });

    resendSuccess.value = true;

    setFieldError('code', undefined);

    setTimeout(() => {
      focusOtpInput();
    });
  } finally {
    resending.value = false;
  }
}
</script>

<template>
  <form ref="form" @submit.prevent="signIn">
    <h1 class="text-3xl font-semibold">{{ strings.code_step.heading }}</h1>

    <!-- eslint-disable vue/no-v-html -->
    <p
      class="mt-3.5 text-xl"
      v-html="
        strings.code_step.paragraph
          .replace('{digits}', codeLength.toString())
          .replace('{email}', email)
      "
    />
    <!-- eslint-enable vue/no-v-html -->

    <OTPInput
      ref="otpInput"
      v-slot="{ slots }"
      v-model="code"
      v-bind="codeProps"
      :maxlength="codeLength"
      :disabled="isSubmitting"
      container-class="mt-6 flex items-center gap-2.5"
      autofocus
      aid="EMPLOYEE_PORTAL_CODE_INPUT"
      @complete="form?.requestSubmit()"
    >
      <div
        v-for="({ isActive, char }, index) in slots"
        :key="index"
        class="relative flex h-12 w-12 items-center justify-center rounded-md border border-gray-200 text-3xl font-semibold outline outline-0"
        :class="{
          'border-2 border-primary': isActive,
          'opacity-60': isSubmitting,
        }"
      >
        <template v-if="char !== null">{{ char }}</template>

        <!-- Emulate a Fake Caret -->
        <div
          v-else-if="isActive"
          class="pointer-events-none absolute inset-0 flex animate-caret-blink items-center justify-center"
        >
          <div class="h-[30px] w-0.5 bg-gray-500" />
        </div>
      </div>
    </OTPInput>

    <WsAlert
      v-if="errors.code"
      open
      type="danger"
      variant="flat"
      aid="EMPLOYEE_PORTAL_CODE_ERROR"
      class="mt-3.5"
    >
      {{ errors.code }}
    </WsAlert>
  </form>

  <div class="mt-10 text-[1rem] font-semibold text-gray-400">
    <span v-if="resendSuccess" class="text-gray-500">
      {{ strings.code_step.code_resend_success }}{{ ' ' }}
    </span>

    <span v-if="secondsLeft > 0">
      {{
        strings.code_step.code_resend_timer.replace(
          '{minutes}',
          getTimeString(secondsLeft)
        )
      }}
    </span>

    <form v-else class="inline-block" @submit.prevent="resendCode">
      {{ strings.code_step.resend_prompt }}

      <button :disabled="resending" class="text-primary underline">
        {{ strings.code_step.resend_button }}
      </button>
    </form>
  </div>
</template>

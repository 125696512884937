<script setup lang="ts">
import { toTypedSchema } from '@vee-validate/zod';
import { useForm } from 'vee-validate';
import { z } from 'zod';

import { WsButton, WsInput } from '@mfl/common-components';
import { employeeAuthGateway } from '@wisestamp/employee-auth-gateway-sdk';

import strings from './employee-portal-signin.strings';

const emit = defineEmits<{
  codeSent: [payload: { email: string; expirationTimestamp: number }];
}>();

const validationSchema = toTypedSchema(
  z.object({
    email: z
      .string()
      .min(1, strings.email_step.required)
      .email(strings.email_step.invalid),
  })
);

const { defineField, handleSubmit, errors, isSubmitting, setFieldError } =
  useForm({ validationSchema });

const [email, emailProps] = defineField('email', {
  validateOnModelUpdate: false,
});
// const [email, emailProps] = defineField('email', (state) => ({
//   // Validate aggressively as long as the input has errors.
//   validateOnModelUpdate: state.errors.length > 0,
// }));

const onSubmit = handleSubmit(async (values) => {
  const res = await employeeAuthGateway.generatePassCode({
    email: values.email,
  });

  if (!res.ok) {
    if (res.reason === 'EMPLOYEE_NOT_FOUND') {
      setFieldError('email', strings.email_step.employee_not_found);
    } else if (res.reason !== 'PASSCODE_ALREADY_SENT') {
      throw new Error('Invalid response from the server');
    }
  }

  if (!res.expirationTimestamp) {
    throw new Error('Response from the server is missing expirationTimestamp');
  }

  emit('codeSent', {
    email: values.email,
    expirationTimestamp: res.expirationTimestamp,
  });
});
</script>

<template>
  <form @submit.prevent="onSubmit">
    <h1 class="text-3xl font-semibold">{{ strings.email_step.heading }}</h1>

    <p class="mt-3.5 text-xl">{{ strings.email_step.paragraph }}</p>

    <WsInput
      v-model="email"
      v-bind="emailProps"
      aid="EMPLOYEE_PORTAL_EMAIL_INPUT"
      type="email"
      name="email"
      autocomplete="email"
      size="lg"
      placeholder="name@example.com"
      autofocus
      :error="errors.email"
      class="mt-6"
    />

    <WsButton
      type="submit"
      aid="EMPLOYEE_PORTAL_SIGNIN_BUTTON"
      :loading="isSubmitting"
      class="mt-5 self-center"
    >
      {{ strings.email_step.submit_button }}
    </WsButton>
  </form>
</template>
